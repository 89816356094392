/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

legend {
  float: unset;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.MuiAvatarGroup-avatar {
  width: 30px;
  height: 30px;
}

.image-hov:hover {
  opacity: 0.7;
}

.card-hov:hover {
  opacity: 0.7;
}

.list-hov:hover {
  background-color: rgba(0, 171, 85, 0.08);
}

/* .MuiTableCell-body{
    padding-top: 0px;
    padding-bottom: 0px;
} */

.onDrag {
  background-color: rgba(0, 171, 85, 0.08);
}

.not-drag {
  background-color: white;
}

.search-input:focus {
  outline: 1px solid rgba(0, 171, 85, 0.08);
}

/* .search-input {
    background-color:white;
} */

.input-field {
  padding-right: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
  /* position: absolute;
    background-color: white; */
  position: fixed;
  background-color: white;
  z-index: 1;
}

.status-circle {
  cursor: pointer;
  height: 25px;
  width: 25px;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-css {
  align-self: center;
}

.main-header {
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-detail {
  align-items: center;
}

.header-task {
  display: flex;
  justify-content: space-evenly;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-date {
  display: flex;
  justify-content: flex-end;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-progress {
  /* border-right: 1px solid #c5c5c5;
    height: 100%;
    padding-top: 13.5px; */
  display: flex;
  justify-content: space-around;
}

.text-area-task {
  resize: none;
}

.simplebar-wrapper {
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

.upload-button {
  background-color: rgb(54 117 136 / 8%);
}

.pointer {
  cursor: pointer;
}

.support-ticket-wrapper {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.support-profile {
  display: flex;
}

.table-pagination > div {
  align-items: baseline;
}

.model-head {
  border-top: 5px solid #367588;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  padding: .8rem .7rem 0rem .7rem;
  border-bottom: 1px solid rgb(169, 169, 169);
}

.model-head > p {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Public Sans", sans-serif;
}
.model-head > span {
  font-size: 24px;
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
}

.detail-section-main {
  padding: 0.5rem 1rem 1.6rem 1rem;
}

.model-data {
  margin-top: 0.9rem;
}

.text-section {
  border: 1px solid rgb(243, 241, 241);
}

.model {
  overflow: auto;
}

.article-model {
  overflow: auto;
}

.name-section {
  font-size: 0.875rem;
  font-weight: bold;
  padding: 10px;
  font-family: "Public Sans", sans-serif;
}

.value-section {
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  padding: 10px;
}

.active_trans {
  /* box-shadow: 1px 1px 1px 1px rgba(180, 180, 180, 0.6); */

  background-color: rgba(54, 117, 136, 0.08) !important;
}

.not_active {
  background-color: #fff !important;
}

.not_active:hover {
  background-color: rgba(54, 117, 136, 0.16) !important;
}

.article-data {
  margin-left: auto;
  margin-right: auto;
}

.headings {
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  font-weight: bold;
}

.description-section {
  line-height: 10px;
}

.article-data {
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
}

.article-description {
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
}

.article-name-section {
  border-bottom: 1px solid rgb(226, 226, 226);
}

.bg {
  background-color: rgb(15, 132, 179);
  color: white;
}

.label {
  background-color: rgb(9, 158, 218) !important;
  color: white !important;
}

.Vissionlabel {
  background-color: rgb(9, 158, 218) !important;
  color: white !important;
  width: 3.7rem;
  padding: 0px 8px;
  font-weight: 700;
  border-radius: 7px;
  font-size: 0.75rem;
  text-align: center;
}

.card-text-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: .6rem;
}
.card-text-section > p {
  font-family: "Public Sans", sans-serif;
  font-size: 0.875rem;
  color: grey;
 
}

.card-main {
  display: flex;
  flex-wrap: wrap;
}

.clearFilter {
  display: none;
}
.showClearFilter {
  display: block;
  font-family: "Public Sans", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
}

.filter {
  font-family: "Public Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
.galleryCard{
  border: 1px solid black;
  border-radius: 5px;
}

.galleryCard > img {
  height: 180px;
  border-radius: 5px 5px 0px 0px;
}

.close-btn {
  margin-right: .3rem;
  margin-top: .3rem;
  position: absolute;
  padding-right: .30rem;
  right: 0;
  color: black;
  font-size: .8rem;
  cursor: pointer;
  background-color: #fff;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
}

.NoImage-text{
margin-left: 33rem;
}

.rbt{
height: 3.4rem;
/* border-radius: 30px; */
}
.rbt-input-main {
  border-radius: 8px;

}
.rbt-input-main:hover{
  border: 1px solid black;
}
.rbt-input-main:focus{
  
  box-shadow: none;
  border: 2px solid #367588;
}

.dropdown-item:hover{
  background-color: rgba(54, 117, 136, 0.08);
  color: black;
}

.labelColor{
  background-color: #367588;
  color: white;
}





